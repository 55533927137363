div.diremo-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0,0,0,0.5);
}
div.diremo-modal-container > .diremo-modal-content {
    overflow-y: auto;
    position: relative;
    top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    padding: 12px;
    border-radius: 10px;
    max-height: calc(100vh - 60px);
}
div.diremo-modal-container > .diremo-modal-content > .container {
    padding: 0;
}
div.diremo-modal-container > .diremo-modal-content .diremo-modal-close-button {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 12px;
}
